<template>
  <div v-if="stowage">

    <b-card-text>Tipo de producto: {{ stowage.product_type.name }}</b-card-text>
    <b-row>
      <b-col md="4">
        <h4 class="text-primary">Estiba #{{ stowage.stowage_number }}</h4>
        <h4>Productos en la estiba: {{ totalProducts }}</h4>
      </b-col>

      <b-col md="4">
        <h4 v-if="box"
          class="text-primary">Caja #{{ box.box_number }}</h4>
        <h4>Cajas en la estiba: {{ stowage.boxes_count }} / {{ stowage.boxes_by_stowage }}</h4>
      </b-col>

      <b-col v-if="box" md="4">
        <h4 class="text-primary">Productos en la caja:</h4>
        <h4>{{ box.products_enlistment_count || 0 }} / {{ box.products_by_box }}</h4>
      </b-col>
    </b-row>

    <hr>

    <p>Para agregar al inventario lee el serial inicial y el serial final</p>

    <!-- User Info: Input Fields -->
    <validation-observer ref="productInventoryValidation" v-if="!stowageFinish">

      <b-form
        @submit.prevent="saveProductInventory">
        <b-row>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Serial inicial*"
                label-for="serial"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Serial inicial"
                  rules="required"
                >

                <b-form-input
                  id="serial"
                  v-model="startSerial"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Serial final*"
                label-for="serial"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Serial final"
                  rules="required"
                >

                <b-form-input
                  id="serial"
                  v-model="finalSerial"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        />
        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.push({name: 'inventory'})"
        >
          Cancelar
        </b-button>

        <b-button
          variant="info"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
          @click="$router.push({name: 'read-inventory-by-box', params: { stowage_id:stowage.id } })"
        >
          Ingresar por caja
        </b-button>
      
      </b-form>
    </validation-observer>

    <div v-else>

      <!-- <h6>Estado: {{ order.order_status.name }}</h6>
      <h6>Descripción: {{ order.order_status.description }}</h6>
      <h6>Cantidad de seriales: {{ order.quantity_products }}</h6> -->

      <h6>Se ha completado la lectura de todos los seriales de la estiba</h6>

      <b-button
        variant="primary"
        @click="$router.push({name: 'stowage-detail', params: { stowage_id:stowage.id } })"
        class="mt-2"
      >
        Ver estiba
      </b-button>


      <b-button
        variant="outline-secondary"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push({name: 'inventory'})"
        class="mt-2  ml-1"
      >
        Ver inventarios
      </b-button>

    </div>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      startSerial: '',
      finalSerial: '',
      required,
      stowage: null,
      stowageFinish: false,
      box: null,
      totalProducts: null,
      loading: false,
    }
  },
  props: {
    stowageId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  created() {
    this.getStowage()
  },
  methods: {
    getStowage() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-stowage-enlistment/' + this.stowageId, { headers }).then(response => {
        console.log('get-stowage-enlistment: ', response)

        this.stowage = response.data.data.stowage
        this.stowageFinish = response.data.data.stowage_finish
        this.box = response.data.data.box
        this.totalProducts = response.data.data.total_products
        
      }).catch( err => {
        console.log(err)
      })
    },
    saveProductInventory() {
      event.preventDefault()
      this.$refs.productInventoryValidation.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/save-product-inventory',{
            start_serial: this.startSerial,
            final_serial: this.finalSerial,
            box_id: this.box.id,
            product_type_id: this.stowage.product_type_id,
          }, { headers }).then(response => {
            console.log('save-product-inventory: ', response)

            this.loading = false

            if(response.data.status) {
              this.startSerial = ''
              this.finalSerial = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.getStowage()
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            this.loading = false

            console.log(err)
          })

        }
      })

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
